/**
 * *Rôle : Loader before get Data Video Similaire
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import '../../../styles/skeleton.scss';

const LoadingPlaceHolderSlider = () => {
	const item = (
		<div className="relative w-full animate-pulse">
			<div className="z-10 h-44 flex items-center justify-center rounded-t-xl rounded-b-md w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200">
				<svg
					className="h-10 w-10 rounded-full p-3 bg-gray-200 text-white"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="currentColor"
					fill="#ffffff"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<polygon points="5 3 19 12 5 21 5 3" />
				</svg>
			</div>
			<div className="absolute bottom-4 left-5 skeleton-box w-10 h-10 rounded-full block z-20"></div>
			<div className="skeleton-box h-3 w-2/6 block mt-0 mb-2"></div>
			<div className="skeleton-box h-3 w-4/6 block"></div>
		</div>
	);

	return (
		<>
			<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
				<div className="block">{item}</div>
				<div className="hidden md:block">{item}</div>
				<div className="hidden lg:block">{item}</div>
			</div>
		</>
	);
};

export default LoadingPlaceHolderSlider;
